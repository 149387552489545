import * as React from "react";
import { PRICING } from "../../constants/pricing";

export default function PricingTiers({ onSignUpClick }) {
  return (
    <div className="pricing-tiers">
      <div className="habit-text">
        <div>
          <div className="days-to">days to</div>
          <div className="bold-white">create</div>
          <div className="bold-white">a habit.</div>
          <div className="day-count gray">30 Days</div>
        </div>
        <div>
          <div className="days-to">days to</div>
          <div className="bold-white">change your</div>
          <div className="bold-white">lifestyle.</div>
          <div className="day-count black">90 Days</div>
        </div>
        <div>
          <div className="days-to">days to</div>
          <div className="bold-white">find your</div>
          <div className="bold-white">#balance.</div>
          <div className="day-count gold">180 Days</div>
        </div>
      </div>
      <div className="boxes">
        <div className="monthly">
          <div>
            <span>monthly</span>
          </div>
          <div className="pricing-content">
            <div className="type">monthly</div>
            <div>
              <div className="price">${PRICING.MONTHLY}/mo</div>
              <div>
                <button
                  className="fancy sub-now-button"
                  onClick={() => onSignUpClick("8")}
                >
                  subscribe now
                </button>
              </div>
            </div>
            <div>
              <div className="small-text">billed monthly.</div>
              <div className="small-text">cancel future payments anytime.</div>
            </div>
          </div>
        </div>
        <div className="tri-monthly">
          <div>
            <span>tri-monthly</span>
          </div>
          <div className="pricing-content">
            <div className="type">tri-monthly</div>
            <div>
              <div className="price">
                $23.33/mo
                <br />
                8% savings off monthly
              </div>
              <div>
                <button
                  className="fancy sub-now-button"
                  onClick={() => onSignUpClick("9")}
                >
                  subscribe now
                </button>
              </div>
            </div>
            <div>
              <div className="small-text">
                ${PRICING.TRI_MONTHLY} billed every 3 months.
              </div>
              <div className="small-text">cancel future payments anytime.</div>
            </div>
          </div>
        </div>
        <div className="semi-annually">
          <div>
            <span>semi-annually</span>
          </div>
          <div className="pricing-content">
            <div className="type">
              <div>semi-annually</div>
              <div className="best-value">best value</div>
            </div>
            <div>
              <div className="price">
                $16.65/mo
                <br />
                17% savings off monthly
              </div>
              <div>
                <button
                  className="fancy sub-now-button"
                  onClick={() => onSignUpClick("10")}
                >
                  subscribe now
                </button>
              </div>
            </div>
            <div>
              <div className="small-text">
                ${PRICING.SEMI_ANNUALLY} billed every 6 months.
              </div>
              <div className="small-text">cancel future payments anytime.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
